import styled from "styled-components"
import ReactGA from 'react-ga4';

import { colors } from "../theme.config"
import { useTranslation } from "../hooks/use-translation"
import { useEffect } from "react"
import { SocialLinks } from "../components/social-links"
import { PrimaryLink } from "../components/links/primary-link"
import { SecondaryLink } from "../components/links/secondary-link"
import { useLocation } from "react-router-dom";
import { Header } from "../components/header/header";

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;

  gap: 24px;

  background-color: ${colors.background};

  .subtitle {
    color: ${colors.secondaryText};
    max-width: 500px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    
    a {
      text-decoration: none;
      color: ${colors.secondaryText};
      cursor: pointer;
    }
  }

  h3 {
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    color: white;
    margin: 0;
  }

  .title {
    text-align: center;

    h1 {
      font-size: 16px;
      color: white;
      font-weight: 600;
    }

    h2 {
      font-size: 14px;
      line-height: 18px;
      font-style: italic;
      font-weight: 300;
      color: white;
      margin: 0;
      color: ${colors.secondaryText};
      opacity: 80%;
    }
  }
`

const PictureContainer = styled.div`
  img {
    object-fit: contain;
    height: 150px;
    width: 150px;
    border-radius: 50%;

    @media (min-width: 768px) {
      height: 200px;
      width: 200px;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  a {
    cursor: pointer;
  }
`

const primaryButtons = [
  {
    title: "⭐ Seja membro do canal ⭐",
    subtitle: "Tenha acesso a vídeos, lives e uma comunidade exclusiva!",
    icon: undefined,
    link: "https://www.youtube.com/channel/UCpKvMmsF6QrkVr_zWaLGK-A/join",
    analyticsEvent: "click_membro_canal_primary_button"
  }
]

const secondaryButtons = [
  {
    title: "Ganhe $300 de crédito na Azion 🇺🇸",
    subtitle: "Crie seus projetos usando tecnologia de Edge Computing (elite)",
    link: "https://tr.ee/console-azion-kipper",
    analyticsEvent: "click_azion"
  },
  {
    title: "Hospede seu Site com Domínio Grátis e SSL na HostGator",
    subtitle: "Use o cupom KIPPERDEV para ter desconto",
    link: "https://afiliados.hostgator.com.br/idevaffiliate.php?id=48171&tid1=youtube&tid2=post&tid3=seisnov24",
    analyticsEvent: "click_hostgator"
  },
  {
    title: "✨ Indicação de itens para setup ✨",
    subtitle: "Todos os itens que uso no meu setup com descontin!",
    link: "https://sandwiche.me/kipper.dev",
    analyticsEvent: "click_setup_general"
  },
  {
    title: "Desconto em Contabilidade Online ✨",
    subtitle: "A Agilize é a contabilidade online que uso e recomendo!",
    important: false,
    showIcon: false,
    link: "https://promo.agilize.com.br/gl-lp-landing-page-influenciador-kipper-dev/?utm_campaign=devkipper&utm_source=influenciador&utm_content=video&utm_medium=youtube&utm_term=lp",
    analyticsEvent: "click_agilize"
  },
  {
    title: "Contato para parcerias 📧",
    subtitle: "Entre em contato com a nossa equipe para detalhes",
    link: "mailto:contato@fernandakipper.com",
    analyticsEvent: "click_parcerias"
  },
  // {
  //   title: "Eventos de tecnologia 🇧🇷",
  //   subtitle: "Próximos eventos tech que irão rolar no Brasil, cupons de desconto e mais",
  //   link: "https://eventostec.com.br/",
  //   analyticsEvent: "click_eventos_tec"
  // },
  // {
  //   title: "Canal do discord 🌍",
  //   subtitle: "Entre para a nossa comunidade e fique por dentro de novidades",
  //   link: "https://discord.gg/D5sStBByFr",
  //   analyticsEvent: "click_discord"
  // }
]

const tabs = [
  {
    title: "Home",
    href: "/",
    selected: true
  },
  {
    title: "Blog",
    href: "https://blog.fernandakipper.com/",
    selected: false
  },
  {
    title: "Eventos",
    href: "https://eventostec.com.br/",
    selected: false
  },
]

export function LinkPage() {
  const location = useLocation()
  const { translate, setLang } = useTranslation();

  useEffect(() => {
    setLang('pt')

    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Página de Link" });
  }, [])

  return (
    <Container>
      <Header tabs={tabs} />
      <PictureContainer>
        <img src="./assets/me-dark-bg.png" alt="Fernanda Kipper Sorrindo" />
      </PictureContainer>
      <div className="title">
        <h1>Fernanda Kipper</h1>
        <h2>@kipper.dev</h2>
      </div>
      <p className="subtitle">👩🏽‍💻 Software Engineer <a href="https://www.mercadolivre.com.br/">@mercadolivre</a><br />
        🏆 Microsoft MVP <br />
        😊 Conteúdos tech feitos com muito carinho</p>
      <ButtonsWrapper>
        {primaryButtons.map(btn =>
          <PrimaryLink
            key={btn.link}
            title={btn.title}
            subtitle={btn.subtitle}
            icon={btn.icon}
            link={btn.link}
            analyticsLabel={btn.analyticsEvent}
          />)}
        <h3>{translate("DISCOUNT_SECTION_TITLE")}👇🏻</h3>
        {secondaryButtons.map(btn =>
          <SecondaryLink
            key={btn.link}
            subtitle={btn.subtitle}
            title={btn.title}
            link={btn.link}
            analyticsLabel={btn.analyticsEvent}
          />)}
      </ButtonsWrapper>
      <SocialLinks showTikTok />
    </Container>
  )
}