import styled from 'styled-components';

import { colors } from '../../theme.config';

interface Props {
  tabs?: { title: string, href: string, selected: boolean }[];
}

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  nav {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  @media screen and (min-width: 800px){
    padding: 0 32px;
  }
`

const TabItem = styled.a<{ selected: boolean }>`
  background-color: ${props => props.selected ? colors.textDark : 'transparent'};
  color: ${colors.secondaryText};
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 400px;
`

export function Header({ tabs }: Props) {
  return (
    <>
      <HeaderContainer>
        <nav>
          {tabs?.map(tab => <TabItem key={tab.href} href={tab.href} target='blank' selected={tab.selected}>{tab.title}</TabItem>)}
        </nav>
      </HeaderContainer>
    </>
  )
}