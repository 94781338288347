import styled from "styled-components";
import { EmailIcon, GithubIcon, InstagramIcon, LinkedinIcon, TikTokIcon, TwitterIcon } from "./icons";
import { useTracking } from "../hooks/use-tracking";

const Container = styled.div`
    display: flex;
    margin-top: 24px;
    align-items: center;
`

const Anchor = styled.a`
    & + & {
        margin-left: 24px;

        svg {
            height: 20px;
            width: 20px;
        }
    }
`

interface SocialLink {
    href: string;
    trackId: string;
    icon: JSX.Element;
    shouldHide?: boolean;
}

interface Props {
    showTikTok?: boolean;
    showLinkedin?: boolean;
}

export function SocialLinks({ showTikTok = false, showLinkedin = false }: Props) {
    const { trackClick } = useTracking();
    const links: SocialLink[] = [
        {
            href: 'mailto:contato@fernandakipper.com',
            trackId: 'click_email',
            icon: <EmailIcon />,
        },
        {
            href: 'https://www.linkedin.com/in/fernanda-kipper/',
            trackId: 'click_linkedin',
            icon: <LinkedinIcon />,
            shouldHide: !showLinkedin,
        },
        {
            href: 'https://twitter.com/kipperdev',
            trackId: 'click_twitter',
            icon: <TwitterIcon />,
        },
        {
            href: 'https://github.com/Fernanda-Kipper?tab=repositories',
            trackId: 'click_github',
            icon: <GithubIcon />,
        },
        {
            href: 'https://www.instagram.com/kipper.dev',
            trackId: 'click_instagram',
            icon: <InstagramIcon />,
        },
        {
            href: 'https://www.tiktok.com/@kipperdev',
            trackId: 'click_tiktok',
            icon: <TikTokIcon />,
            shouldHide: !showTikTok,
        }
    ]
    return (
        <Container>
            {links.map(({ href, trackId, icon, shouldHide = false }) => (
                !shouldHide && (
                    <Anchor
                        key={href}
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => trackClick(trackId)}
                    >
                        {icon}
                    </Anchor>
                )
            ))}
        </Container>
    )
}