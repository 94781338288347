import { useEffect } from 'react';
import { useLocation } from 'react-router';
import ReactGA from 'react-ga4';

const links = {
  'fluencypass': 'https://fluencypass.com/plans',
  'nordvpn': 'https://go.nordvpn.net/SH5ts',
  'veroo': 'https://veroo.cafe/fernandakipper',
  'discord': 'https://discord.gg/D5sStBByFr',
  'youtube': 'https://youtube.com/@kipperdev',
  'instagram': 'https://instagram.com/kipper.dev',
}

const linksWithUtmAndCoupon = {

}

export function Redirect() {
  function defaultRedirect() {
    window.location.href = 'https://fernandakipper.com/links';
  }

  const location = useLocation();
  useEffect(() => {
    try {
      const pathName = location.pathname.replace('/', '');
      let link = links[pathName as keyof typeof links];
      if (location.search) {
        // in case of utm and coupon being informed in the url, we use them
        link += location.search;
      } else if (linksWithUtmAndCoupon[pathName as keyof typeof linksWithUtmAndCoupon]) {
        // in case of utm and coupon NOT being informed in the url, we use the default
        link = linksWithUtmAndCoupon[pathName as keyof typeof linksWithUtmAndCoupon];
      }
      if (link) {
        ReactGA.send({ hitType: "pageview", page: pathName, title: "Página de Redirect" });
        window.location.href = link;
      } else {
        defaultRedirect();
      }
    } catch (error) {
      defaultRedirect()
    }
  }, [location]);

  return <p>Garantindo o seu desconto...</p>;
};